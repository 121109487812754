@charset "UTF-8";

@import "mixins";

.antragsgruenInitForm {
  .testDB {
    overflow: auto;
    margin-bottom: 20px;
    > * {
      float: left;
    }
    .testDBcaller {
      margin-right: 20px;
    }
    .testDBsuccess {
      padding-top: 7px;
      color: green;
      font-weight: bold;
    }
    .testDBerror {
      padding-top: 7px;
      color: red;
      font-weight: bold;
    }
  }
  .createTables {
    .alreadyCreatedHint {
      display: none;
      padding-left: 20px;
    }
    &.alreadyCreated {
      color: gray;
      .alreadyCreatedHint {
        display: block;
      }
    }
  }
}

.siteCreate {
  .wizard {
    font-family: "Open Sans", sans-serif;
  }
  fieldset {
    > legend {
      border-bottom: none;
      margin-bottom: 0;
      text-align: center;
      font-size: 24px;
      padding-top: 30px;
    }
    > .description {
      text-align: center;
      font-style: italic;
    }
    .options {
      margin-top: 20px;
      text-align: center;
      vertical-align: top;
      @include outlineOnFocusWithinNoBorder();
    }
    .radio-checkbox-label {
      display: inline-block;
      width: 190px;
      height: 190px;
      border: solid 1px #999;
      vertical-align: top;
      cursor: pointer;
      margin: 20px;
      position: relative;
      padding: 10px 5px;
      border-radius: 10px;
      background-color: #fbfffb;
      box-shadow: 0 0 3px 1px rgba(0, 50, 0, 0.25);

      @media screen and (max-width: 525px) {
        width: 140px;
        height: 140px;
      }

      &.active {
        background-color: #9f9;
        box-shadow: 0 0 4px 2px rgba(0, 50, 0, 0.35);
      }

      .title {
        font-family: "Open Sans", sans-serif;
        margin-top: 60px;
        display: block;
        font-size: 24px;
        @media screen and (max-width: 525px) {
          margin-top: 32px;
        }
        &.long {
          font-size: 19px;
        }
        @media screen and (max-width: 525px) {
          font-size: 20px;
          &.long {
            font-size: 17px;
          }
        }
      }
      &.two-lines {
        .title {
          margin-top: 20px;
          @media screen and (max-width: 525px) {
            margin-top: 7px;
          }
        }
      }
      &.three-lines {
        .title {
          margin-top: 0;
        }
      }
      &.date-label {
        .title {
          margin-top: 40px;
          @media screen and (max-width: 525px) {
            margin-top: 7px;
          }
        }
      }
      .description {
        display: block;
        font-weight: normal;
        margin-top: 5px;
      }
      &.description-first {
        .description {
          margin-top: 40px;
          @media screen and (max-width: 525px) {
            margin-top: 7px;
          }
        }
        .title {
          margin-top: 5px;
        }
      }
      .input {
        display: block;
        text-align: center;
        width: 100%;
        position: absolute;
        bottom: 10px;
        margin-left: -5px;
        input:focus {
          outline: none;
        }
        input[type=radio], input[type=checkbox] {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border: none;
          border-radius: 0;

          &:checked:before{
            position: relative;
            display: inline-block;
            font-family: 'Glyphicons Halflings';
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "\e084";
          }
        }
      }
      .form-control {
        padding: 6px;
      }
    }
  }
  .navigation {
    margin-top: 20px;
    overflow: auto;
    padding: 20px 100px;
    @media screen and (max-width: 525px) {
      padding-right: 20px;
      padding-left: 20px;
    }

    .btn-prev {
      float: left;
    }
    .btn-next {
      float: right;
    }
  }
  .step-pane {
    &.active {

    }
    &.inactive {
      display: none;
    }
  }

  .siteData {
    .error {
      display: none;
    }
    .has-error .error {
      display: block;
    }
    .success {
      display: none;
    }
    .has-success .success {
      display: block;
    }
    .firstrow {
      margin-top: 20px;
    }
    .subdomainRow {
      .subdomainError {
        color: red;
        font-weight: bold;
      }
      .form-group {
        width: 160px;
        display: inline-block;
      }
      #siteSubdomain {
        padding-right: 20px;
      }
    }
    .contactRow {
      > label {
        width: 100%;
      }
      small {
        font-weight: normal;
      }
    }
    .fakeurl .form-group {
      margin-bottom: 0;
    }
  }

  .textform {
    margin-top: 10px;

    > div {
      margin-top: 10px;
    }
  }
  @media screen and (min-width: 700px) {
    .textform {
      width: 400px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.wording_manifesto .only-motion {
    display: none;
  }
  &.wording_motion .only-manifesto {
    display: none;
  }
}
